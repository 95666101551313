<template>
    <div data-comp="Input" class="font-heebo text-gray-500">
        <label v-if="label !== null" :for="id">
            {{ label }}
        </label>

        <input
            :id="id"
            :type="type"
            ref="input"
            :class="[error !== '' ? 'border-red-500' : '', 'form-input']"
            :placeholder="placeholder"
            :value="modelValue"
            :autocomplete="autoComplete"
            :spellcheck="spellCheck"
            @input="handleInput"
            @click="$emit('update:cursor', $event.target.selectionStart)"
            :disabled="disabled"
        />

        <div v-if="error" class="form-error">
            {{ error }}
        </div>
    </div>
</template>

<script setup>
    import { onMounted, ref } from 'vue';

    const emit = defineEmits(['update:modelValue', 'update:cursor', 'debounced']);

    const input = ref(null);
    const debounceTimer = ref(null);

    const props = defineProps({
        id: {type: String, default: ''},
        type: {type: String, default: 'text'},
        label: {type: String, default: null},
        placeholder: {type: String, default: ''},
        modelValue: {type: String, default: ''},
        error: {type: String, default: ''},
        autoComplete: {type: String, default: 'off'},
        spellCheck: {type: Boolean, default: false},
        disabled: {type: Boolean, default: false},
        debounceTime: {
            type: Number,
            default: 300,
        },
    });

    onMounted(() => {
        if (input.value.hasAttribute('autofocus')) {
            input.value.focus();
        }
    });

    const handleInput = (event) => {
        emit('update:modelValue', event.target.value);

        clearTimeout(debounceTimer.value);
        debounceTimer.value = setTimeout(() => {
            emit('debounced', event.target.value);
        }, props.debounceTime);
    };
</script>

<style lang="scss" scoped>
    [data-comp="Input"] {
        @apply flex flex-col gap-2;

        label {
            @apply text-overline text-gray-700;
        }

        input {
            @apply w-full p-2 block text-body border border-gray-300 rounded-md focus:ring-0
            focus:border-black transition ease-in-out duration-300;
        }
    }
</style>
